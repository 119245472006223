<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
      >
        <a-row>
          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="标题">
              <a-input
                v-decorator="[
                  'title',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="分类">
              <a-select
                mode="multiple"
                style="width: 100%"
                v-decorator="[
                  'category',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-select-option
                  v-for="item in categoryList"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="地点">
              <a-input
                v-decorator="[
                  'address',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="12" :md="12" :sm="24">
            <a-form-item label="首页是否显示">
              <a-radio-group
                v-decorator="[
                  'stared',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
              >
                <a-radio :value="0"> 否 </a-radio>
                <a-radio :value="1"> 是 </a-radio>
              </a-radio-group>
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="简介"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                  'content',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="规模"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea
                :auto-size="{ minRows: 3 }"
                v-decorator="[
                  'scale',
                  { rules: [{ required: true, message: '请输入！' }] },
                ]"
              />
            </a-form-item>
          </a-col>
          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item
              label="获奖情况"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 18 }"
            >
              <a-textarea :auto-size="{ minRows: 3 }" v-decorator="['award']" />
            </a-form-item>
          </a-col>

          <a-col :lg="24" :md="24" :sm="24">
            <a-form-item :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
              <span slot="label" class="required">图片</span>
              <FileUpload @uploaded="uploaded">
                <a-button type="primary" icon="upload"> 上传 </a-button>
                <span style="color: #999; padding-left: 8px"
                  >图片大小建议在5MB以下</span
                >
              </FileUpload>

              <div class="link-list">
                <div class="between link" v-for="item in fileList" :key="item">
                  <a target="_blank" download :href="item">{{ item }}</a>
                  <div class="control" @click="deleteFile(item)">
                    <a-icon type="delete" />
                  </div>
                </div>
              </div>
            </a-form-item>
          </a-col>
        </a-row>

        <div class="center">
          <a-space>
            <a-button type="primary" html-type="submit" :loading="saveLoading"
              >保存</a-button
            >
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import { edit, fetchDetail } from "@/api/website/case";
import FileUpload from "@/components/file-upload";
import { categoryList } from "./data";
export default {
  name: "websiteCaseEdit",

  components: {
    FileUpload,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      categoryList,
      fileList: [],
      saveLoading: false,
      id: null,
      detail: {},
    };
  },

  activated() {
    const { query } = this.$route;
    const { id } = query || {};

    if (id && this.id !== id) {
      this.id = id;
      fetchDetail({
        id,
      }).then((res) => {
        this.detail = res;
        this.form.setFieldsValue({
          title: res.title,
          category: res.category.split("/"),
          address: res.address,
          content: res.content,
          scale: res.scale,
          award: res.award,
          stared: res.stared,
        });

        this.fileList = res.images.split(",");
      });
    }
  },

  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (!this.fileList.length) {
            this.$message.error("请上传图片！");
            return;
          }

          this.saveLoading = true;

          edit({
            id: this.id,
            ...this.detail,
            ...values,
            category: values.category.join("/"),
            images: this.fileList.join(),
          })
            .then(() => {})
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },

    uploaded(list) {
      this.fileList = [
        ...this.fileList,
        ...list.map((item) => item.completePath),
      ];
    },

    deleteFile(item) {
      const index = this.fileList.findIndex((path) => path === item);
      if (index > -1) {
        this.fileList.splice(index, 1);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 24px 160px;
}

.center {
  margin-top: 80px;
}

.control {
  cursor: pointer;
}
</style>
